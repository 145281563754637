import React from "react";

import "../fonts.css";

// Imports
import Header from "../components/Header";
import MainBanner from "../components/MainBanner";
import ProductBanner from "../components/ProductBanner";
import ProductContainer from "../components/ProductContainer";
import Footer from "../components/Footer";
//import Produkte_1 from "../constant/Produkte_1";

function App() {
  return (
    <div>
      <Header />
      <MainBanner />

      <div
        style={{
          display: "flex",
          flexDirection: "row", // Change to 'row' for desktop view
          flexWrap: "wrap", // Allow wrapping on smaller screens
          justifyContent: "center", // Center the banners horizontally
          margin: "50px 20px",
          gap: "10%",
        }}
      >
        <div className="product-banner-container">
          <ProductBanner
            image="/photos/Shkronja_Shqip.jpg"
            title="Produkti me i ri ne treg!"
            description="🔠 36 shkronja te mëdha të shtypit & 36 shkronja të vogla të shtypit
            🖌️Marker dhe fshirëse + 📋 Dy tabela shkrimi të vijëzuara"
            style={{
              paddingTop: "6.25%",
            }}
          />
        </div>

        <div className="product-banner-container">
          <ProductBanner
            image="/photos/toy_color.jpg"
            title="Distributor Zyrtar i Toy Color"
            description="E hena vjen me produkte te reja nga Toy Color dhe sete dhuratash per vogelushet e apasionuar pas piktutes. Direkt nga Italia Italia vetem per ju. 🥰"
            style={{
              paddingTop: "6.25%", // Example for a 16:9 aspect ratio
            }}
          />
        </div>
      </div>
      <h2>Produkte Trend</h2>
      <div
        style={{
          display: "flex",
          width: "94%",
          backgroundColor: "#ff6347",
          height: 8,
          margin: "auto",
        }}
      ></div>

      {/* <ProductContainer products={Produkte_1} /> */}

      <ProductContainer />

      <div style={{ marginTop: "5%" }}>
        <Footer />
      </div>
    </div>
  );
}

export default App;
