import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// Screens
import HomePage from "./Screens/HomePage";
import ProductDetail from "./Screens/ProductDetail";
import RrethNesh from "./Screens/RrethNesh";
import Kontakt from "./Screens/Kontakt";
import Produktet from "./Screens/Produktet";

//Lista me produktet
//import Produkte_1 from "./constant/Produkte_1"; // Adjust the path as necessary

const Navigation = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/products/:productName"
            element={<ProductDetail collectionName="Produktet" />}
          />
          <Route
            path="/full-products/:productName"
            element={<ProductDetail collectionName="ProduktetFull" />}
          />
          <Route path="/about" element={<RrethNesh />} />
          <Route path="/contact" element={<Kontakt />} />
          <Route path="/products" element={<Produktet />} />
        </Routes>
      </div>
    </Router>
  );
};

export default Navigation;
