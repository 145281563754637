import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { AuthContext } from "../AuthProvider"; // Ensure this path is correct
import Header from "../components/Header";
import Footer from "../components/Footer";

const ProductDetail = ({ collectionName }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const { productName } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true); // Ensure loading is true initially

  useEffect(() => {
    if (isAuthenticated) {
      const db = getFirestore();
      const fetchProduct = async () => {
        setLoading(true); // Start loading when the fetch starts
        const productsRef = collection(db, collectionName);
        const decodedName = decodeURIComponent(productName.replace(/-/g, " "));
        const q = query(productsRef, where("Emri", "==", decodedName));
        try {
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            setProduct({ id: doc.id, ...doc.data() });
          } else {
            console.log("No such product!");
            setProduct(null); // Explicitly set product to null if not found
          }
        } catch (error) {
          console.error("Error fetching product:", error);
        }
        setLoading(false); // End loading regardless of the outcome
      };

      fetchProduct();
    }
  }, [productName, collectionName, isAuthenticated]);

  if (loading) {
    return (
      <div>
        <h2>Po ngarkohen detajet e produktit...</h2>
      </div>
    ); // Show loading message while loading
  }

  if (!product) {
    return (
      <div>
        <h1>Produkti nuk u gjet.</h1>
        <a
          onClick={() => navigate("/products")}
          className="view-details-button"
        >
          Shkoni Mbrapa
        </a>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div style={styles.productDetail}>
        <img
          src={product.Foto}
          alt={product.Emri}
          style={styles.productImage}
        />
        <div style={styles.productInfo}>
          <h2 style={styles.h2}>{product.Emri}</h2>
          <p style={styles.p}>{product.Cmimi} Lekë</p>
          <p style={styles.p}>{product.Pershkrimi}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
const styles = {
  productDetail: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    maxWidth: "1200px",
    margin: "auto",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },
  productImage: {
    width: "100%",
    maxWidth: "500px",
    height: "auto",
    objectFit: "cover",
    marginBottom: "20px",
    borderRadius: "10px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  productInfo: {
    textAlign: "center",
    padding: "0 20px",
  },
  h2: {
    fontSize: "2.5rem",
    marginBottom: "10px",
    color: "#333",
  },
  p: {
    fontSize: "1.5rem",
    marginBottom: "10px",
    color: "#071e26",
  },
  addToCartButton: {
    backgroundColor: "#ff6347",
    color: "#ffffff",
    padding: "15px 30px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    fontSize: "1.2rem",
    marginTop: "20px",
  },
  addToCartButtonHover: {
    backgroundColor: "#ff4500",
  },
};

// Media queries for responsiveness
const mediaQueries = {
  "@media (max-width: 600px)": {
    productDetail: {
      flexDirection: "column",
      padding: "10px",
    },
    productImage: {
      maxWidth: "100%",
    },
    h2: {
      fontSize: "2rem",
    },
    p: {
      fontSize: "1rem",
    },
    addToCartButton: {
      padding: "10px 20px",
      fontSize: "1rem",
    },
  },
};

export default ProductDetail;
