import React, { useEffect, useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import { AuthContext } from "../AuthProvider";
import Header from "../components/Header copy";

const ProductPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const db = getFirestore();
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    const fetchProducts = async () => {
      if (isAuthenticated) {
        try {
          const productsRef = collection(db, "ProduktetFull");
          const q = query(productsRef, orderBy("Emri"), limit(20));
          const querySnapshot = await getDocs(q);
          const productList = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setProducts(productList);
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setHasMore(querySnapshot.docs.length === 10);
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [db, isAuthenticated]);

  const fetchMoreProducts = useCallback(async () => {
    if (isAuthenticated) {
      setLoading(true);
      try {
        const productsRef = collection(db, "ProduktetFull");
        const q = query(
          productsRef,
          orderBy("Emri"),
          startAfter(lastVisible),
          limit(10)
        );
        const querySnapshot = await getDocs(q);
        const newProducts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts((prevProducts) => [...prevProducts, ...newProducts]);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(querySnapshot.docs.length === 10);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  }, [db, lastVisible, isAuthenticated]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      loading ||
      !hasMore
    )
      return;
    fetchMoreProducts();
  }, [loading, hasMore, fetchMoreProducts]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore, handleScroll]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Header />
      <h2>Të gjitha produktet:</h2>
      <div
        style={{
          display: "flex",
          width: "90%",
          backgroundColor: "#ff6347",
          height: 8,
          margin: "auto",
        }}
      ></div>
      <div className="product-showcase">
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <img
              src={product.Foto}
              alt={product.Emri}
              className="product-image"
            />
            <div className="product-info">
              <h3>{product.Emri}</h3>
              <p>{product.Cmimi}L</p>
              <button
                onClick={() =>
                  navigate(
                    `/full-products/${product.Emri.replace(/\s+/g, "-")}`,
                    {
                      state: { collectionName: "ProduktetFull" },
                    }
                  )
                }
                className="view-details-button"
              >
                Shikoni Detajet
              </button>
            </div>
          </div>
        ))}
      </div>
      {loading && <div>Loading more products...</div>}
    </div>
  );
};

export default ProductPage;
