import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import "./Header.css";

const Header = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchQuery.trim() !== "") {
        const productsRef = collection(db, "ProduktetFull");
        const q = query(
          productsRef,
          where("EmriLower", ">=", searchQuery.toLowerCase()),
          where("EmriLower", "<=", searchQuery.toLowerCase() + "\uf8ff")
        );
        const querySnapshot = await getDocs(q);
        const results = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSearchResults(results);
      } else {
        setSearchResults([]);
      }
    };

    fetchSearchResults();
  }, [searchQuery, db]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchResultClick = (productId) => {
    navigate(`/full-products/${productId}`, {
      state: { collectionName: "ProduktetFull" },
    });
    setSearchQuery("");
  };

  return (
    <header className="header">
      <div className="header-container">
        <nav className="header-nav-links">
          <ul>
            <li>
              <a href="/">Kryesore</a>
            </li>
            <li>
              <a href="/products">Produktet</a>
            </li>
            <li>
              <a href="/about">Rreth Nesh</a>
            </li>
            <li>
              <a href="/contact">Dyqanet Fizike</a>
            </li>
          </ul>
        </nav>

        <div className="name-header">
          <a href="/">Dua Të Luaj </a>
        </div>
        <div className="name-header-v2">
          <a href="/"> </a>
        </div>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Kërkoni..."
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          {searchResults.length > 0 && (
            <ul className="search-results">
              {searchResults.map((product) => (
                <li
                  key={product.id}
                  onClick={() => handleSearchResultClick(product.Emri)}
                >
                  <img
                    src={product.Foto}
                    alt={product.Emri}
                    className="search-result-image"
                  />
                  <span>{product.Emri}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
