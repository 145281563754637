import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { AuthContext } from "../AuthProvider";
import "./ProductContainer.css";

const ProductContainer = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const db = getFirestore();
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    const fetchProducts = async () => {
      if (isAuthenticated) {
        try {
          const querySnapshot = await getDocs(collection(db, "Produktet"));
          const productList = [];
          querySnapshot.forEach((doc) => {
            productList.push({ id: doc.id, ...doc.data() });
          });
          setProducts(productList);
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [db, isAuthenticated]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="product-showcase">
      {products.map((product) => (
        <div key={product.id} className="product-card">
          <img
            src={product.Foto}
            alt={product.Emri}
            className="product-image"
          />
          <div className="product-info">
            <h3>{product.Emri}</h3>
            <p>{product.Cmimi}L</p>
            <button
              onClick={() =>
                navigate(`/products/${product.Emri.replace(/\s+/g, "-")}`, {
                  state: { collectionName: "Produktet" },
                })
              }
              className="view-details-button"
            >
              Shikoni Detajet
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductContainer;
