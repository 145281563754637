// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInAnonymously,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyAiXvDu4jjYkuFWFjKzKgx8zaQKulX71ZQ",
  authDomain: "duateluaj-web.firebaseapp.com",
  projectId: "duateluaj-web",
  storageBucket: "duateluaj-web.appspot.com",
  messagingSenderId: "116054665546",
  appId: "1:116054665546:web:18ddbd9d89ce18766379b3",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const initializeAuth = async () => {
  try {
    await setPersistence(auth, browserSessionPersistence);
    await signInAnonymously(auth);
    console.log("User signed in anonymously");
  } catch (error) {
    console.error("Authentication failed:", error);
  }
};

export { auth };
// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
export default firebase;
