// src/components/Footer.js
import React from "react";
import "./Footer.css"; // Import the CSS file
import { FaInstagram, FaFacebook } from "react-icons/fa";
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h2>Na Vizitoni:</h2>
          <p>📍 Kodra e Diellit, Rruga Shkelqim Fusha, Tiranë</p>
          <p>📍 Rruga Plepa, Vaqarr, Tiranë</p>
        </div>
        <div>
          <h2>Na Kontaktoni:</h2>
          <p>Email: officeinfiniti1@gmail.com</p>
          <p>Telefon: +355 69 480 7688</p>
        </div>
        <div className="footer-section">
          <h2>Na Ndiqni:</h2>
          <div className="social-links">
            <a
              href="https://www.instagram.com/dua_te_luaj/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram style={{ color: "#f8d97b", fontSize: "40px" }} />
            </a>
            <a
              href="https://www.facebook.com/ramovishpk"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FaFacebook style={{ color: "#343a9f", fontSize: "39px" }} />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          <strong>&copy; {new Date().getFullYear()} Dua Të Luaj</strong> - Të
          Drejtat e Rezervuara.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
