// src/components/ProductBanner.js
import React from "react";
import "./ProductBanner.css";

const ProductBanner = ({ className, image, title, description, style }) => {
  return (
    <div className={`product-banner ${className}`}>
      <div className="banner-image-wrapper" style={style}>
        <img src={image} alt={title} className="banner-image" />
      </div>
      <div className="banner-content">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ProductBanner;
