import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

const Kontakt = () => {
  const apiKey = process.env.GOOGLE_MAP_API_KEY; // Ensure this is correctly set
  const [selected, setSelected] = useState(null);

  const locations = [
    {
      lat: 41.312516,
      lng: 19.803055,
      name: "Dyqani 1",
      address: "Kodra e Diellit, Tiranë",
    },
    {
      lat: 41.303417,
      lng: 19.745819,
      name: "Dyqani/Showroom-i",
      address: "Vaqarr, Tiranë",
    },
  ];

  const containerStyle = {
    width: "70%",
    height: "50vh",
    margin: "0 auto",
  };

  const center = {
    lat: 41.312516,
    lng: 19.803055,
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Header />
      <div style={{ marginBottom: "3%" }}>
        <h1> Vendndodhjet: </h1>
        <LoadScript
          googleMapsApiKey="AIzaSyAQKVfgDPstgQpabR1xKKS-dcnVKyeXspY"
          loading="async"
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={55}
            center={center}
          >
            {locations.map((location, index) => (
              <Marker
                key={index}
                position={{ lat: location.lat, lng: location.lng }}
                onClick={() => {
                  setSelected(location);
                }}
              />
            ))}
            {selected ? (
              <InfoWindow
                position={{ lat: selected.lat, lng: selected.lng }}
                onCloseClick={() => {
                  setSelected(null);
                }}
              >
                <div>
                  <h2>{selected.name}</h2>
                  <p>{selected.address}</p>
                </div>
              </InfoWindow>
            ) : null}
          </GoogleMap>
        </LoadScript>
      </div>
      <Footer />
    </div>
  );
};

export default Kontakt;
