// AuthProvider.js
import React, { useEffect, useState } from "react";
import { auth, initializeAuth } from "./Firebase";
import { onAuthStateChanged } from "firebase/auth";

export const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authError, setAuthError] = useState(null);

  useEffect(() => {
    initializeAuth()
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            console.log("User is signed in", user.uid);
            setIsAuthenticated(true);
            setAuthError(null);
          } else {
            console.log("User is signed out");
            setIsAuthenticated(false);
            setAuthError(null);
          }
        });

        return () => unsubscribe();
      })
      .catch((error) => {
        console.error("Authentication failed:", error);
        setAuthError(error);
      });
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, authError }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
