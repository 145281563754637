import React, { useState } from "react";
import Header from "../components/Header";

export default function AboutUs() {
  const [showDifference, setShowDifference] = useState(false);
  const [showBrands, setShowBrands] = useState(false);
  const fontStyles = `
    @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
  `;
  const pageStyle = {
    color: "#333",
    maxWidth: "80%",
    margin: "0 auto",
    padding: "20px",
    fontSize: "75",
  };

  const contentStyle = {
    backgroundColor: "#f9f9f9",
    padding: "40px",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  };

  const headingStyle = {
    color: "#007bff",
    marginBottom: "20px",
    fontSize: "32px",
    fontWeight: "bold",
    textTransform: "uppercase",
    cursor: "pointer",
  };
  const paragraphStyle = {
    fontFamily: "Merriweather', serif",
    fontstyle: "normal",
    lineHeight: "1.6",
    marginBottom: "18px",
    fontSize: "20px",
  };
  const listStyle = {
    listStyleType: "none",
    padding: "0",
    display: "none",
  };

  const listItemStyle = {
    marginBottom: "10px",
    fontSize: "16px",
    lineHeight: "1.4",
    color: "#555",
  };

  const toggleDifference = () => {
    setShowDifference(!showDifference);
    setShowBrands(false);
  };

  const toggleBrands = () => {
    setShowBrands(!showBrands);
    setShowDifference(false);
  };

  return (
    <div>
      <Header />
      <div style={pageStyle}>
        <div style={contentStyle}>
          <h2 style={headingStyle}>About Us</h2>
          <p style={paragraphStyle}>
            Founded by two young entrepreneurs who in the first moments
            conceived the creation of a network of several stores that offered
            the same standards of service, prices, and products throughout
            Albania. Initially, the company was known as “Kancelari Infiniti”.
            It started in a small shop on the outskirts of Tirana in Selite in
            2003, expanding further in many areas of Tirana and other cities.
            The company specializes in meeting the needs of every market
            segment, with office materials, stationery products, printing inks,
            and recently a primary focus has been given to children, with fun
            and didactic toys. An important moment for our company was the year
            2017 when the company underwent a change in structure. The company
            sold all the stores under its responsibility, focused on wholesale
            sales, and signing supply contracts with market chains throughout
            Albania, seeing it as a better and more profitable opportunity.
          </p>
          <h2 style={headingStyle} onClick={toggleDifference}>
            What makes Ramovi'S different?
          </h2>
          <ul
            style={{ ...listStyle, display: showDifference ? "block" : "none" }}
          >
            <li style={listItemStyle}>20 years experience</li>
            <li style={listItemStyle}>Dedication and professionalism</li>
            <li style={listItemStyle}>Wide range of clients</li>
            <li style={listItemStyle}>
              We put love and passion into everything we do.
            </li>
            <li style={listItemStyle}>
              Our different and innovative marketing campaigns
            </li>
            <li style={listItemStyle}>Finding the best suppliers</li>
            <li style={listItemStyle}>The best price-quality ratio</li>
          </ul>
          <h2 style={headingStyle} onClick={toggleBrands}>
            What are the brands we distribute?
          </h2>
          <ul style={{ ...listStyle, display: showBrands ? "block" : "none" }}>
            <li style={listItemStyle}>TOY COLOR</li>
            <li style={listItemStyle}>MILAN</li>
            <li style={listItemStyle}>FABER-CASTELL</li>
            <li style={listItemStyle}>INSTAX</li>
            <li style={listItemStyle}>BELLA LUNA TOYS</li>
            <li style={listItemStyle}>LÜKS ÖZEN – PLAY DOUGHS</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
