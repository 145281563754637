// src/components/MainBanner.js
import React from "react";
import "./MainBanner.css"; // Import the CSS file

const MainBanner = () => {
  return (
    <div className="main-banner">
      <div className="banner-content">
        <h1>Zbuloni botën e LODRAVE</h1>
        <p style={{ fontSize: 21 }}>Dua Të Luaj - Pjesë e Ramovi'S</p>
        {/* <button className="cta-button">Bli Tani</button> */}
      </div>
    </div>
  );
};

export default MainBanner;
